/* You can add global styles to this file, and also import other style files */
$brand-color: #009444;
$defaultgreen: #009444;
$defaultborder: #11642e;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

.height-60{
  height: 60vh;
}
.text-brand {
  color: #009444;
}

.height-10{
  height: 10vh;
}

html body {
  margin: 0;
  box-sizing: inherit;
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

// Progress bar
div.loading-div {
  padding-left: 25vw;
  padding-top: 20vh;
}
.progress {
  background-color: none !important;
}
.scroll {
  display: block;
  border: 0px solid red;

  margin-right: 5px;
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
}


// mat icons //

// setting icons
.mfaToggle .material-icons {
  vertical-align: center !important;
}
.material-icons {
  vertical-align: bottom;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #009444 !important;
}

.mat-icon {
  margin-right: 10px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  width: 114px;
}

.mat-button-toggle
  .mat-button-toggle-appearance-standard
  .mat-button-toggle-checked {
  background-color: #3a3a3a;
  color: rgba(255, 255, 255, 0.54);
}
// setting MFA toggle
.restriction {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    width: 350px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    font-size: 0.75em;
    text-align: left;
  }
  .mat-icon {
    padding-left: 158px;
  }
  .block {
    .mat-icon {
      padding-left: 192px;
    }
  }
  .no {
    .mat-icon {
      padding-left: 152px;
    }
  }
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  width: 140px;
  font-size: 0.75em;
}

.mat-form-field-flex {
  width: 300px !important;
}

// country field

.mat-form-field-appearance-outline .mat-form-field-flex {
  max-width: 300px;
}

// tooltip

.mat-tooltip {
  font-size: 1rem !important;
}

// matcheckbox

.mat-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #17a34d !important;
}

@media only screen and (max-width: 1500px) {
  body {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 4px;
  }
  // previous button
  .prev-nex-button {
    .btn {
      max-width: 100px;
      padding: 2px;
    }
  }
  // setting MFA toggle
  .restriction {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      width: 60vw;
      border-bottom: 1px solid E5E6E7;
    }
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    width: 30vw;
  }
}

@media only screen and (max-width: 1000px) {
  // material-button
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    width: 108px;
    font-size: 0.75em;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    max-width: 200px;
  }

  .mat-button-toggle
    .mat-button-toggle-appearance-standard
    .mat-button-toggle-checked {
    background-color: #3a3a3a;
    color: rgba(255, 255, 255, 0.54);
  }

  // setting MFA toggle
  .restriction {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      width: 200px;
      border-bottom: 1px solid rgb(0, 0, 0, 0.1);
      font-size: 0.75em;
    }
    .mat-icon {
      padding-left: 64px;
    }
    .block {
      .mat-icon {
        padding-left: 99px;
      }
    }
    .no {
      .mat-icon {
        padding-left: 58px;
      }
    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border: none !important; 
}
.mat-icon.mat-accent {
  color: #c2c2c2 !important;
}
// material-toggle
.mat-button-toggle-group{
  border: solid 2px #11642e !important ;
}
.mfa-toggle .mat-button-toggle{
  border: solid 2px #1c351c !important;
}
.mat-button-toggle-checked {
  background-color: $brand-color !important;
  color: #FFFFFF !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #009444 !important;
}

// country-material

.mat-form-field-appearance-outline{ 
  
.mat-form-field-flex {
  border: solid 2px green;
  border-radius: 4px;
}


}
.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: none !important;
}

  // Shepperd tour guide
.shepherd-has-title .shepherd-content .shepherd-header {
  background-color: #00c159 !important;
}
.shepherd-element {
    border-radius: 9px !important;
    z-index: 70 !important;
  }
  .shepherd-has-title .shepherd-content .shepherd-header  .shepherd-title{
    color: #FFFFFF !important;
    }
  .shepherd-element {
    border: solid 4px #16202D;
    border: none !important;
    box-shadow: 2px 4px 6px gray !important;
  }
  .shepherd-has-title .shepherd-content .shepherd-footer  .shepherd-button{
    background-color: #00c159 !important;
    color: #FFFFFF !important;
    }
  
    .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
      background-color: #FFFFFF !important;
    }
  
  
  .form-check-input:checked {
    background-color: $brand-color;
    border-color: $brand-color;
  }
  
  
  .shepherd-text{
    white-space: pre-wrap
  }
  
  
  .shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
    top: -11px !important;
  }
  
  .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
    border-left: solid #00c159 2px;
    border-top: solid #00c159 2px;
    border-right:  none !important;
    bottom: -3px
  }
  
  .shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
    bottom: -11px !important;
  }
  
  .shepherd-element.shepherd-has-title[data-popper-placement^=top]>.shepherd-arrow:before {
    border-bottom: solid #00c159 2px;
    border-right: solid #00c159 2px;
    border-left: none;
    border-top: none;
    bottom: 2px
  }
  
  .shepherd-element[data-popper-placement^=right]>.shepherd-arrow {
    left: -11px !important;
  }
  .shepherd-element.shepherd-has-title[data-popper-placement^=right]>.shepherd-arrow:before {
    border-left: solid #00c159 2px;
    border-bottom: solid #00c159 2px;
    border-right: none !important;
    border-top: none !important;
    left: 1px;
  }
  
  
  .shepherd-arrow:before {
    content: "";
    border-right: solid #00c159 2px;
    border-top: solid #00c159 2px;
    right: -1px;
    bottom: -3px;
  }


